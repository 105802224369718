.inventoryViewContainer {
  .inventoryView {
    margin: 0 20px;
    .inventoryView-card {
      display: flex;
      align-items: center;
      margin-top: 38px;
      background: rgba(255, 255, 255, 0.08);
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      min-height: 110px;
      padding: 0 30px;
      h3,
      p {
        margin: 0;
      }
      h3 {
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
      }
      span {
        font-weight: 600;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.38);
      }
      p {
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        margin-top: 10px;
      }
      img {
        width: 70px;
        height: 70px;
        border-radius: 1000px;
        margin-right: 30px;
      }
    }
    .inventoryView-orderBtn {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #ff6f00;
      background: none;
      outline: none;
      border: none;
    }
    .inventoryView-infoList {
      padding: 30px 20px;
      .inventoryView-info {
        margin: 12px 0;
        display: flex;
        p {
          flex: 1;
        }
        :nth-child(1) {
          font-weight: 600;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
        }
        :nth-child(2) {
          font-weight: 600;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.87);
        }
      }
    }
  }
}
.delete_product {
  background-color: transparent !important;
  color: rgb(255, 102, 0);
}
