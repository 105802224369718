.autoLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}
.autoLoader-small {
  min-height: 10vh;
}
