.auto-backBtn {
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.auto-backBtn:active {
  background-color: rgb(50, 50, 50);
}
