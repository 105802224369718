.employeeForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  width: 100%;
  .ant-form {
    width: 90%;
  }
  .ant-input,
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 50px;
  }
  .employeeForm-submit {
    width: 100%;
    margin: auto;
  }
}
