.jobCardListContainer {
  position: relative;
  .manual_jobcard {
    position: absolute;
    right: 12px;
    top: 25px;
    color: white;
    background: #ff6f00;
    border-radius: 12px;
    border: none;
    padding: 12px 12px;
  }
}
