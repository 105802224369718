.bayListContainer {
  .bayListCard {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    min-height: 81px;
    display: flex;
    margin: 20px 20px;
    padding: 20px 5px;
    color: rgba(255, 255, 255, 0.87);
    p,
    h3 {
      margin: 0;
    }
    .bayListCard-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        font-weight: 300;
        font-size: 40px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
    .bayListCard-right {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      text-align: right;
      padding-right: 10px;
      span {
        font-weight: 600;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }

  @media screen and (min-width: 700px) {
    .bayListCard {
      margin: 20px 100px;
    }
  }
}
