.autoListCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
  height: 110px;
  min-width: 330px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  padding: 15px 20px;
  margin: 6px;
  h3,
  p {
    margin: 0;
  }
  header,
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
  }
  footer {
    p {
      font-size: 13px;
      font-weight: 600;
    }
  }
  .autoListCard-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    .autoListCard-bodyLeft {
      display: flex;
      align-items: center;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
    }
    p {
      padding-top: 5px;
    }
    span {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      color: #ffffff;
    }
    img {
      margin-right: 10px;
      width: 45px;
      height: 45px;
      border-radius: 1000px;
    }
  }
}
