.autoHome {
  padding: 20px;
}

.autoMenuIcon {
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}
.autoHome-header {
  font-weight: 600;
  font-size: 24px;
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.87);
}
.weekly_service {
  height: 336px;
  left: 392px;
  top: 104px;
  margin-bottom: 24px;
  span {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        /* Medium Emphasis */

        color: rgba(255, 255, 255, 0.6);
      }
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 120% */

        letter-spacing: 0.15px;

        color: #ffffff;
      }
    }
  }

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    #121212;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.15px;

    color: #ffffff;
    padding-bottom: 29px;
  }
}
.chart_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.chart_part1 {
  display: flex;
  margin-bottom: 24px;

  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  border: 0.5px solid rgb(70, 70, 70);
  border-radius: 12px;
  width: 100%;
  height: 200px;
  div {
    height: unset !important;
  }
  span {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    h6 {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height, or 117% */

      letter-spacing: 0.95px;

      /* Medium Emphasis */

      color: rgba(255, 255, 255, 0.6);
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height, or 130% */

      letter-spacing: 0.95px;

      color: #ffffff;
    }
  }
}
.line_chart {
  height: auto !important;
  canvas {
    width: 100% !important;
    height: 90px !important;
  }
}
.daily_report {
  .daily_report_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      padding-bottom: 29px;
      svg {
        color: white;
      }
    }
  }
  div {
    span {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.08);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        color: rgba(255, 255, 255, 0.6);
      }
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        color: #ffffff;
      }
    }
  }
}
#daily_report_table {
  .ant-table-thead > tr > th {
    background: #1a1a1a;
    color: white;
  }
  .ant-table-tbody > tr > td {
    background: #121212;
    border-bottom: unset;
    color: white;
  }
}
.create_job_btn {
  background: #ff6f00;
  border-radius: 16px;
  width: 182px;
  height: 46px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-transform: capitalize;

  color: #ffffff;
}
