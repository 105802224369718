.jobListCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  .jobListCard {
    min-width: 350px;
    width: fit-content;
    min-height: 145px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    p,
    h3 {
      margin: 0;
    }
    header {
      display: flex;
      align-items: center;
      p {
        flex: 1;
        font-weight: 600;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.38);
      }
      p:nth-child(2) {
        text-align: right;
      }
    }
    .jobListCard-body {
      margin: 10px 0;
      .jobListCard-bodyItems {
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 5px;
      }
      .jobListCard-bodyWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h3 {
        flex: 1;
        font-weight: 600;
        font-size: 24px;
        color: rgba(255, 255, 255, 0.87);
      }
      p {
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-weight: 600;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.16);
      }
    }
  }

  .jobListServiceCard {
    .jobListCard-body {
      p {
        font-weight: 600;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }
}
