.employeeDashMain {
  min-height: 100vh;
  width: 100%;
  padding: 20px;

  .employeeDashContent {
    .employeeContentTitle {
      font-weight: 600;
      font-size: 24px;
      margin: 20px 0;
      color: rgba(255, 255, 255, 0.87);
    }
  }
}
.employeeDashMenuIcon {
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}
