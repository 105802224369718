.jobAppbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  min-height: 60px;
  //   background: rgba(255, 255, 255, 0.08);
  font-weight: 300;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.87);
  .jobAppbar-mid {
    display: flex;
    align-items: center;
    .jobAppbar-left {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin: 0;
      margin-left: 10px;
    }
  }
}
