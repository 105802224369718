.jobCardView-col-right {
  display: flex;
  align-items: center;
  justify-content: center;
  .jobCardView-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;
    mix-blend-mode: difference !important;
    color: white !important;
    width: 200px;
    img {
      width: 100%;
      max-width: 100px;
      margin: 3rem;
    }
    .jobCardView-circle {
      background-color: transparent;
      width: 30px;
      height: 30px;
      border: 1px solid white;
      border-radius: 50%;
      position: absolute;
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
    .jobCardView-circle-active {
      background-color: #ff6f00;
      width: 30px;
      height: 30px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      position: absolute;
      &:hover {
        cursor: pointer;
        background-color: #ff862a;
      }
    }
    .jobCardView-circle-front {
      top: 0;
    }
    .jobCardView-circle-front_left {
      top: 50px;
      left: 0;
    }
    .jobCardView-circle-front_right {
      top: 50px;
      right: 0;
    }
    .jobCardView-circle-rear {
      bottom: 0;
    }
    .jobCardView-circle-rear_left {
      bottom: 50px;
      left: 0;
    }
    .jobCardView-circle-rear_right {
      bottom: 50px;
      right: 0;
    }
    .jobCardView-circle-side_left {
      left: 0;
    }
    .jobCardView-circle-side_right {
      right: 0;
    }
  }
}
.upload_btn {
  width: 120px;
  height: 120px;
  border: 1px dashed rgb(175, 175, 175);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.remark_img_dlt {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: rgb(218, 78, 14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  svg {
    color: white !important;
  }
}
