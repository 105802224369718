.employeeCardsContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactsTop {
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 95%;
  padding: 0 3%;
  height: 40px;
  transition: ease-in-out 0.2s;
  svg {
    font-size: 22px;
    color: #ddc187;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    padding: 0 10px;
    transition: ease-in-out 0.2s;
    background-color: transparent;
    border: 1px solid rgb(85, 85, 85);
    padding: 12px ;
    border-radius: 12px;
  }
  .contactsSearch {
    background-color: white;
    border-radius: 12px;
    svg {
      color: #000000;
      font-size: 18px;
    }
  }
}
