.jobCard-btn,
.jobCard-btn:hover,
.jobCard-btn:focus,
.jobCard-btn:active,
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  background: linear-gradient(
      0deg,
      rgba(18, 18, 18, 0.16),
      rgba(18, 18, 18, 0.16)
    ),
    linear-gradient(113.96deg, #f05c2d 0%, #fcaa2e 100%);
  border-radius: 8px;
  border: none;
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  opacity: 0.5;
}
