.employeeExpandedContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  .avatarContainer {
    padding: 20px 0;
  }

  .contentTable {
    margin-top: 20px;
    width: 100%;
    .tableRow {
      width: 100%;
      display: flex;
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
      p {
        margin: 0;
        padding: 0;
        width: 65%;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.87);
        overflow: hidden;

        &:first-child {
          width: 35%;
          text-transform: capitalize;
        }

        &:nth-child(2) {
          text-overflow: ellipsis;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
