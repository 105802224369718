.loginContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
.loginBanner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.loginSpace {
  flex: 0.4;
}
.loginPortal {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .ant-form {
    width: 80%;
  }
  .ant-input,
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 58px;
  }
  .ant-btn {
    margin: auto;
  }
}
