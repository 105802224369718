.serviceCardContainer {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#list_services table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
#list_services th,
#list_services td {
  padding: 24px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.1px;
}
#list_services input {
  color: black !important;
}
.list_services_head {
  background: rgba(255, 255, 255, 0.08);
}
