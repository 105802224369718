.bayAssignForm {
  .bayAssignForm-BayList {
    display: flex;
    align-items: center;
    //   justify-content: space-around;
    flex-wrap: wrap;
    p {
      margin: 5px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 45px;
      min-height: 45px;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 600;
      font-size: 24px;
      cursor: pointer;
      background: transparent;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid rgba(255, 255, 255, 0.87);
      transition: all ease-in-out 0.2s;
    }
    p.bayAssignForm-BaySelected {
      background: rgba(255, 255, 255, 0.87);
      color: #121212;
      border: 1px solid rgba(255, 255, 255, 0.87);
    }
  }

  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
  }
}
