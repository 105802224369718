@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #121212 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  /* mix-blend-mode: difference !important;
  color: white !important; */
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: unset !important;
}
a {
  text-decoration: none !important;
}
@media print {
  body {
    width: 29.7cm;
    height: 29.7cm;
    margin: 30mm 0mm 10mm 0mm;
    /* change the margins as you want them to be. */
  }
}
@media print {
  .noprint {
    display: none;
  }
}
@media screen and (max-width: 578px) {
  .report_media_wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
  .ant-drawer-content-wrapper {
    width: 240px !important;
  }
  .SideNav-text {
    font-size: 11px!important;
  }
}
