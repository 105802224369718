.page-item.active .page-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #0071cc;
  border-color: #0071cc;
}

.list-group-item.active {
  background-color: #0071cc;
  border-color: #0071cc;
}

.page-link {
  color: #0071cc;
}
.page-link:hover {
  color: #005da8;
}

/* Pagination */
.page-link {
  border-color: #f4f4f4;
  border-radius: 0.25rem;
  margin: 0 0.3rem;
}

.page-item.disabled .page-link {
  border-color: #f4f4f4;
}
.report_media_wrap {
  display: flex;
  justify-content: space-between;
}

//bootstrap
