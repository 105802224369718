.employeeEditContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  .employeEditAvatarContainer {
    padding: 20px 0;
  }
}
