.autoDetailCard {
  margin: 0 20px;
  .autoDetailCard-top {
    display: flex;
    align-items: center;
    margin-top: 38px;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    min-height: 110px;
    padding: 0 30px;
    h3,
    p {
      margin: 0;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
    }
    span {
      font-weight: 600;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.38);
    }
    p {
      font-weight: 600;
      font-size: 12px;
      color: #ffffff;
      margin-top: 10px;
    }
    img {
      width: 70px;
      height: 70px;
      border-radius: 1000px;
      margin-right: 20px;
    }
  }
  .autoDetailCard-infoList {
    padding: 30px 10px;
    .autoDetailCard-info {
      input {
        color: black !important;
      }
      margin: 12px 0;
      display: flex;
      :nth-child(1) {
        flex: 0.7;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
      :nth-child(2) {
        flex: 1;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
