.jobCardView-drawer {
  .ant-upload.ant-upload-select-picture-card {
    background-color: #fafafa20 !important;
  }
  .ant-upload-list-item-actions {
    a {
      display: none;
    }
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: none;
  }
  .ant-drawer-header {
    background: #242424 !important;
    border-bottom: 1px solid #242424 !important;
    .ant-drawer-title {
      color: #ffffff !important;
    }
    button {
      color: #ffffff !important;
    }
  }
}

.jobCardView {
  margin: 18px;
  .jobCardView-col-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .jobCardView-title {
    font-weight: 600;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.87);
    padding: 4px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
    margin-top: 1rem;
  }
  .cart_jobcart {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .jobCardView-header {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.87);
    }
    p {
      text-align: right;
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.87);
      span {
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }
  .jobCardView-jobs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .jobCardView-job {
      min-width: 350px;
      min-height: 180px;
      padding: 20px;
      margin: 10px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 8px;
      // transform: matrix(1, 0, 0, 1, 0, 0);
      p {
        text-align: right;
      }
      h3 {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
  }
  .jobCardView-details {
    .jobCardView-detail {
      display: flex;
      align-items: center;
      p:nth-child(1) {
        flex: 1;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
      p:nth-child(2) {
        flex: 1.2;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
      }
      .jobCardView-detailCall {
        flex: 0.2;
      }
    }
  }
  .jobCardView-invoices {
    input {
      color: black !important;
    }
    .jobCardView-invoices-hd {
      font-weight: 600;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.38);
      margin: 0;
    }
    .jobCardView-invoices-id {
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.87);
      margin: 0;
    }
    .jobCardView-invoice {
      display: flex;
      p {
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
        margin: 8px 0;
        span {
          font-weight: 600;
          font-size: 11px;
          color: rgba(255, 255, 255, 0.38);
        }
      }
      p:nth-child(1) {
        flex: 0.1;
      }
      p:nth-child(2) {
        flex: 0.75;
      }
      p:nth-child(3) {
        flex: 0.15;
        text-align: right;
      }
    }
    .jobCardView-invoice-divider {
      height: 2px;
      background-color: rgba(255, 255, 255, 0.38);
      border-radius: 10px;
    }
  }
  .jobCardView-productsAdd {
    margin: 25px 0;
    .ant-input {
      flex: 4;
      min-height: 50px;
      background-color: transparent;
    }
    .jobCardView-productsAddIcon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      border-radius: 8px;
      margin-left: 8px;
      border: 1px solid rgba(255, 255, 255, 0.08);
    }
  }
  .jobCardView-products {
    margin-top: 30px;
    .jobCardView-product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.12);
      padding: 12px 10px;
      h3,
      p {
        margin: 0;
      }
      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #ffffff;
      }
      p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      .jobCardView-productLeft {
        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  .jobCardView-footer {
    padding: 25px 0 50px 0;
    p {
      margin: 10px;
    }
    .jobCardView-footerInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      p {
        font-weight: 600;
        font-size: 14px;
        margin: 20px 0;
        span {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      button {
        outline: none;
        border: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #ff0000;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 700px) {
    margin: 40px;
  }
  @media screen and (min-width: 1000px) {
    margin: 50px;
  }
}
.manualCardcontainer {
  input {
    width: 334px;
    height: 58px;
  }
}

.manualCardcontainer {
  .ant-select {
    width: 334px !important;
    height: 58px !important;
  }
  .ant-input-affix-wrapper {
    width: 334px !important;
    height: 58px !important;
    background: #252525;
    border-radius: 12px;
  }
  .ant-select-selector {
    height: 100% !important;
  }
  .verified svg {
    color: #52ff00;
    font-size: 24px;
  }
  .verify_failed svg {
    color: #ff0000;
    font-size: 24px;
  }
}
.signup_vehicle_wrap {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.signup_vehicle_wrap p {
  color: rgba(255, 255, 255, 0.6);
  padding-top: 12px;
  white-space: initial;
  width: 100px;
}
.signup_border {
  border: 1px solid white;
}
.signup_vehicle_wrap img {
  width: 120px;
  cursor: pointer;
}
#cart_table .ant-table-thead > tr > th {
  background: #1b1b1b;
  color: white;
}
#cart_table .ant-table-tbody > tr > td {
  background: #121212 !important;
  color: white;
}
.side_image {
  width: 90px;
  margin: 12px;
  object-fit: contain;
}
.ant-table-cell input {
  color: black !important;
}
@media print {
  .noPrint {
    display: none;
  }
}
h1 {
  color: #f6f6;
}
.remark_input_field {
  border-radius: 12px;
  width: 100%;
  padding: 8px 12px;
  margin-top: 24px;
  color: white;
  background: #1b1b1b;
}
.remark_add_wrap {
  button {
    border-radius: 12px;
    border: none;
    color: black;
    background: #ff6f00 !important;
    padding: 8px 8px;
    font-size: 12px;
    margin-top: 12px;
  }
}
.wrap_remark {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    color: black !important;
  }
}
