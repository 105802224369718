.serviceContent {
  padding: 40px 20px;
  width: 100%;
  table {
    td {
      padding: 1rem;
    }
  }
  .incentive-con {
    padding: 1rem;
    .input-comp {
      margin: 1rem 0;
      height: 50px;
    }
    .btn-con {
      margin: 1rem 0;
    }
  }
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #ffffff;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.6);
  }
}
