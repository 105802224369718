.SideNav {
  .SideNav-info {
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);

    h3 {
      font-weight: 600;
      font-size: 16px;

      color: #ffffff;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
    }
  }

  .SideNav-text {
    padding-left: 20px;
    color: #ffffff;
  }

  .SideNav-menu {
    margin-top: 10px;
  }

  .SideNav-icon {
    color: rgba(255, 255, 255, 0.6);
  }
}
