.bayViewContainer {
  .bayView {
    padding: 10px 30px;
    p,
    h1,
    h4 {
      margin: 0;
    }
    .bayView-dtl {
      margin: 30px 0;
      p {
        font-weight: 300;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin: 10px 0;
        padding: 5px 2px;
      }
      h4 {
        font-weight: 600;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);
      }
      h1 {
        font-weight: 300;
        font-size: 80px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
  }
}
