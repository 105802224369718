.servicesCreateContent {
  .addServiceBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    position: absolute;
    width: 324px;
    height: 46px;
    background: #ff6f00;
    border-radius: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #ffffff;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-collapse {
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.16);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
    }

    .ant-collapse-item,
    .ant-collapse-header {
      min-height: 56px;
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
    }

    .ant-collapse-content {
      .ant-checkbox-wrapper {
        width: 100%;
        margin: 0;
        min-height: 30px;

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #ffffffcb;
        }

        .ant-checkbox-inner {
          background: transparent;
          border: 1px solid #ff6f00;
        }
      }

      .ant-checkbox-checked {
        &::after {
          border: 1px solid #ff6f00;
        }
      }
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #ffffff;
  }
}
