.jobCardHistoryContainer {
  .jobCardHistory-title {
    font-weight: 600;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.87);
    padding: 4px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
    text-align: left;
    width: 100%;
    margin: 0 20px;
    margin-top: 20px;
  }
  .jobCardHistory-date {
    width: 85%;
    margin: auto;
    .ant-picker {
      min-height: 45px;
    }
  }
}
