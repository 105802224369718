.viewReportContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .viewReportContainer-dateInput {
    width: 90%;
    min-height: 50px;
  }
}
.autoRepCardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  .autoRepCard {
    min-width: 350px;
    width: fit-content;
    min-height: 80px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    padding: 15px 20px;
    margin: 5px 5px;
    p,
    h3 {
      margin: 0;
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      margin: 10px 0;
      color: rgba(255, 255, 255, 0.87);
    }
    p {
      flex: 1;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
    p:nth-child(1) {
      justify-content: flex-start;
    }
    p:nth-child(2) {
      justify-content: flex-end;
    }
    header,
    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    footer p {
      color: rgb(255, 110, 85);
      font-size: 14px;
    }
  }
}
