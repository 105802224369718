.clr-danger {
  color: #fc5c2e !important;
  font-weight: 600;
}
.clr-warn {
  color: #fcaa2e !important;
  font-weight: 600;
}
.clr-msg {
  color: #3ec500 !important;
  font-weight: 600;
}
.ant-empty-description{
  color: white;
}
.autoItemCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.16);
  margin: 0 20px;
  padding: 0 10px;
  min-height: 70px;
  p,
  h4 {
    margin: 0;
    color: #ffffff;
  }
  .autoItemCard-info {
    h4,
    p {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 12px;
    }
    p {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .autoItemCard-left {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 1000px;
    }
  }
  @media screen and (max-width: 355px) {
    .autoItemCard-info {
      h4,
      p {
        max-width: 120px;
      }
    }
  }
  @media screen and (min-width: 500px) {
    margin: 0 25px;
    .autoItemCard-info {
      h4,
      p {
        max-width: 270px;
      }
    }
  }
  @media screen and (min-width: 700px) {
    padding: 0 15px;
    margin: 0 30px;
    .autoItemCard-info {
      h4,
      p {
        max-width: 450px;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    padding: 0 25px;
    margin: 0 40px;
    .autoItemCard-info {
      h4,
      p {
        max-width: 700px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .autoItemCard-info {
      h4,
      p {
        max-width: 850px;
      }
    }
  }
  @media screen and (min-width: 1500px) {
    .autoItemCard-info {
      h4,
      p {
        max-width: 1300px;
      }
    }
  }
}
